<template>
  <div>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-9 col-md-12">
          <div class="card mb-3">
            <div class="card-header">
                <h3 class="mb-0">Receiving Money by UK Bank Transfer</h3>
              </div>

            <div class="card-body min-vh" v-loading="loading">
              <ul class="list-group mb-4" v-for="currency in Object.keys(bankAccount)" :key="currency">
                <li class="list-group-item" v-for="item of bankAccount[currency]" :key="item.uid">
                  <div class="d-flex flex-column position-relative">
                      <h6 class="mb-3 text-sm">{{ currency }} {{ item.cardholderid }}</h6>
                      <div class="position-absolute top-0 right-0" v-if="!isFeatureCard && item.currency === 'GBP'">
                        <el-button size="mini" type="primary" @click="$router.push(`/transfer?bankholderid=${item.cardholderid}`)">Transfer</el-button>
                      </div>
                      <div class="mb-3 text-xs">{{ item.international.text }}</div>
                      <div class="bg-light-gray p-2">
                        <div class="mb-2" v-if="['GBP', 'EUR'].includes(item.currency)">
                          <div class="mb-2 text-xs">{{ (item.currency === 'GBP' ? 'UK Domestic' : 'EUR SEPA') }}</div>
                          <div class="mb-2 text-xs">{{ labelAccount(item.currency) }}: <b>{{ item.accountnumber }}</b> {{ labelSortcode(item.currency) }}: <b>{{ item.sortcode }}</b></div>
                        </div>
                        <div class="mb-2 text-xs">International</div>
                        <div class="mb-2 text-xs">IBAN: <b> {{ item.international.IBAN }}</b> BIC: <b>{{ item.international.BIC }}</b> Reference: <b>{{ item.international.reference }}</b></div>
                        <div class="text-xs">Correspondent Bank SWIFT: <b>{{ item.international.correspondent }}</b></div>
                      </div>
                  </div>
          
                </li>
   
              </ul>

            </div>
          </div>
        </div>

        <div class="col-xl-3 col-md-12">
          <div class="card mb-3">
              <div class="card-body">
                <Shortcut/>
              </div> 
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BankApi from "@/api/bankaccount";
import Shortcut from '@/components/MenuShortcut.vue'
export default {
  name: "dashboard",
  components: {
    Shortcut
  },
  computed:{
    getname(){
      return this.$store.getters.name
    },
    isBusiness(){
      return this.$store.getters.business
    },
    isFeatureCard(){
      return this.$store.getters.feature.card
    },
  },
  data(){
    return {
      loading: false,
      bankAccount: []
    }
  },
  methods:{
    labelAccount(currency){
      return currency.toUpperCase() === 'GBP' ? 'Acount Number' : 'IBAN'
    },
    labelSortcode(currency){
      return currency.toUpperCase() === 'GBP' ? 'Sort Code' : 'BIC'
    },
    getBankAccount(){
      this.loading = true
      BankApi.getMultiAccount().then(({result, data, message})=>{
        this.loading = false
        if(result){
          this.bankAccount = data
        }else{
          this.$message.error(message)
        }
      })
    },
    balance(){
      this.$emit("updateBalance");
    }
  },
  created(){
    this.getBankAccount()
  }
};
</script>
<style lang="scss"></style>
